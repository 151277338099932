import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image, Icon, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle, MdArrowForward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Студія татуювання Demini
			</title>
			<meta name={"description"} content={"Студія татуювання Demini - це місце, де творчість та артистизм гармонійно поєднуються з індивідуальністю."} />
			<meta property={"og:title"} content={"Головна | Студія татуювання Demini"} />
			<meta property={"og:description"} content={"Студія татуювання Demini - це місце, де творчість та артистизм гармонійно поєднуються з індивідуальністю."} />
			<meta property={"og:image"} content={"https://demini-black.com/media/banner.jpg"} />
			<link rel={"shortcut icon"} href={"https://demini-black.com/media/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://demini-black.com/media/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://demini-black.com/media/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://demini-black.com/media/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://demini-black.com/media/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://demini-black.com/media/images.png"} />
			<meta name={"msapplication-TileColor"} content={"https://demini-black.com/media/images.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="112px 0 0px 0"
			background="linear-gradient(0deg,rgba(15, 15, 15, 0.5) 0%,rgba(0, 0, 0, 0.6) 100%),--color-darkL2 url(https://demini-black.com/media/banner.jpg)"
			md-padding="96px 0 0px 0"
			sm-padding="72px 0 0px 0"
			min-height="90vh"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 40px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Студія татуювання Demini
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					width="92%"
				>
					Місце, де творчість та артистизм гармонійно поєднуються з індивідуальністю. Ми віримо, що татуювання - це не просто чорнило, це форма самовираження, яка назавжди закарбовує ваші історії та переконання на вашому полотні шкіри.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s linear 0s"
					hover-background="--color-orange"
					transition="background-color 0.2s linear 0s"
				>
					Записатися на консультацію
				</Link>
			</Box>
		</Section>
		<Section
			padding="100px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-30"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				padding="60px 40px"
				sm-padding="30px 30px 60px 30px"
				background="rgba(255, 255, 255, 0.06) radial-gradient(circle at center,rgba(255, 255, 255, 0.01) 0%,rgba(255, 255, 255, 0.02) 100%)"
				border-radius="30px"
				border-width="1px"
				border-style="solid"
				border-color="rgba(255, 255, 255, 0.03)"
			/>
			<Box
				display="flex"
				flex="0 0 40%"
				lg-flex="1 1 100%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				align-items="center"
				lg-justify-content="flex-start"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					height="23em"
					width="23em"
					min-height="260px"
					lg-width="auto"
					lg-height="auto"
					object-fit="cover"
					src="https://demini-black.com/media/image1.jpg"
					display="block"
					border-radius="20px"
				/>
			</Box>
			<Box
				display="flex"
				flex="1 1 55%"
				flex-direction="column"
				justify-content="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0"
				padding="0px 0px 0 0"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 2.3em/1.5 --fontFamily-sans"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 26px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 30px/1.2 --fontFamily-sans"
				>
					Що ми пропонуємо
				</Text>
				<Text
					as="p"
					margin="1.2em 0 0 0"
					font="normal 100 20px/1.5 --fontFamily-sans"
					font-size="1.2em"
					font-weight="300"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					lg-max-width="640px"
				>
					Ми прагнемо забезпечити комфортне та творче середовище як для наших майстрів, так і для клієнтів.
				</Text>
				<Box
					display="flex"
					flex-direction="row"
					grid-gap="18px"
					margin="30px 0px 0px 0px"
					font="400 1.1em --fontFamily-sans"
					lg-width="100%"
					lg-justify-content="flex-start"
					flex-wrap="wrap"
					color="--light"
					sm-margin="24px 0 0 0"
					lg-margin="32px 0px 0px 0px"
				>
					<List
						as="ul"
						padding="0"
						margin="0"
						list-style-type="none"
						display="flex"
						flex-direction="column"
						grid-gap="18px"
						lg-max-width="640px"
					>
						<Box margin="0px 0px 0px 0px" display="flex" grid-gap="10px">
							<Icon display="block" category="md" icon={MdCheckCircle} size="20px" />
							<Text padding="0" margin="0">
								Татуювання на замовлення
							</Text>
						</Box>
						<Box margin="0px 0px 0px 0px" display="flex" grid-gap="10px">
							<Icon display="block" category="md" icon={MdCheckCircle} size="20px" />
							<Text padding="0" margin="0">
								Видалення татуювання
							</Text>
						</Box>
						<Box margin="0px 0px 0px 0px" display="flex" grid-gap="10px">
							<Icon display="block" category="md" icon={MdCheckCircle} size="20px" />
							<Text padding="0" margin="0">
								Оновлення татуювання
							</Text>
						</Box>
						<Box margin="0px 0px 0px 0px" display="flex" grid-gap="10px">
							<Icon display="block" category="md" icon={MdCheckCircle} size="20px" />
							<Text padding="0" margin="0">
								Засоби для догляду
							</Text>
						</Box>
					</List>
				</Box>
				<Link
					href="/contact-us"
					color="--secondary"
					margin="2em 0 0 0"
					font="500 1.1em --fontFamily-sans"
					text-decoration-line="initial"
					display="flex"
					grid-gap="4px"
					transition="all 0.3s ease"
					padding="0"
					justify-content="flex-start"
					flex="0 0 auto"
					order="0"
					align-self="flex-start"
					overflow-x="hidden"
					overflow-y="hidden"
					lg-margin="2em 0 0 0"
					align-items="flex-start"
					hover-border-bottom="1px solid #909dfd"
				>
					Записатися
					<Icon category="md" icon={MdArrowForward} size="1.1em" />
				</Link>
			</Box>
		</Section>
		<Section
			padding="80px 0 90px 0"
			lg-padding="80px 0 80px 0"
			background="--color-darkL2"
			quarkly-title="Content-15"
			color="--light"
		>
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
					margin="0px 0px 35px 0px"
					sm-flex-direction="column"
				>
					<Image
						src="https://demini-black.com/media/image2.jpg"
						display="block"
						object-fit="cover"
						margin="0px 5px 0px 0px"
						width="26%"
						sm-width="100%"
						sm-margin="0 0 10px 0"
					/>
					<Image
						src="https://demini-black.com/media/image3.jpg"
						display="block"
						object-fit="cover"
						margin="0px 0px 0px 5px"
						width="37%"
						sm-width="100%"
						sm-margin="0 0 10px 0"
					/>
					<Image
						src="https://demini-black.com/media/image4.jpg"
						display="block"
						object-fit="cover"
						margin="0px 0px 0px 5px"
						width="37%"
						sm-width="100%"
						sm-margin="0px 0px 0px 0"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 25px 0px"
						font="normal 300 18px/1.5 --fontFamily-sans"
						color="--light"
						text-align="left"
						max-width="640px"
					>
						Ваша безпека - наш пріоритет. У Demini ми дотримуємося суворих гігієнічних стандартів, забезпечуючи чисте та стерильне середовище. Все обладнання ретельно стерилізується, а майстри одягають одноразові рукавички для всіх процедур.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});